@import url('https://fonts.googleapis.com/css2?family=Quantico:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.card {
  width:280px;
  display: block;
  margin: 50px auto;
  box-shadow: 10px 5px 40px 20px darken(#921cac, 5%);
  transition: .25s;

  &:hover {
    box-shadow: 10px 5px 40px 20px darken(#921cac, 10%);
    cursor: pointer;

    .button-primary {
      transform: translate(10px, 0);
    }
  }
}
.roster-title{
  font-family: 'Press Start 2P', cursive;
  color:#6F2232;
}

.card-body:hover{
  box-shadow: 8px 18px 18px rgba(223, 67, 67, 0.824);
}
.card-header {
  height: 150px;
  width: 100%;
  width:100%;
  background-size:cover;
  color:#000000;
}

.card-header {
  width: 30%;
  height: 175px;
  float: right;
}

.card-body {
  padding: 25px;
  min-width: 200px;
  min-height: 175px;
  background-color:#fff;
  width: 100%;
}

.card-body h2 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  font-family: 'press start 2p', cursive;
}

.date {
  font-size: 11px;
  font-weight: 600;
  color: grey;
}

.body-content {
  padding-bottom: 40px;
  font-size: 13px;
  line-height: 1.8;
  width: 70%;
}

.button-primary {
  border: none;
  box-shadow: none;
  font-family: inherit;
  background-color:transparent;
  color: #a619c6ba;
  font-size: 15px;
  transition:.25s;

  i{
    padding-right:4px;
  }
}

.cardGamer {
  margin-right: 5px;
  color: black;
}

.cardGamer h2{
  color:rgba(173, 50, 55, 0.865);
}

.roster-container {
  margin-top: 8rem;
  text-align: center;
  max-width: 95%;
  margin-right: auto;
  margin-left: auto;
}

.roster-main__header-container {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: space-between;
  column-gap: 5rem;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .roster-main__header-container {
    grid-template-columns: 1fr;
    row-gap: 2rem;
    text-align: center;
  }
}
  

.article-link{
  padding-bottom: 2rem;
  font-family: 'Nunito', sans-serif;
}

.nateDawg{
  font-family: 'Quantico', sans-serif;
}