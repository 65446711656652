.news-articles {
  margin: 20px;
}

.article {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #e8e8e8;
  background-color: #040505;
  padding: 20px;
  transition: box-shadow 0.3s ease-in-out;
  max-width: 1080px;
}

.news-articles .article:hover {
  box-shadow: 8px 18px 18px rgba(223, 67, 67, 0.2);
}

.article-title {
  font-size: 20px;
  font-weight: bold;
}

.article-content {
  margin-top: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.article-info {
  color: #888;
  font-size: 14px;
  float: right;
}

.article-author {
  font-weight: bold;
}

.main__news{
  margin-top: 8rem;
}

.news__title{
  margin-bottom: 2rem;
  text-align: center;
}

.news__title{
  font-family: 'Press Start 2P', cursive;
  color: #6F2232;
}
