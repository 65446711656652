@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.shop-title{
    font-family: 'Press Start 2P', cursive;
    color:#6F2232;
}

.shop-image{
    height: 450px;
    object-fit: cover;
}