.aboutSection{
    margin-top: 1rem;
    padding: 1rem .5rem 1rem 1rem;
    background-color: #6F2232;
    color: white;
    border-radius: 10px;
    font-size: large;
    font-weight: 600;
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
}

.aboutHeader{
    margin-top: 7rem;
    text-align: center;
    font-family: 'Press Start 2P', cursive;
    color:#6f2231;
    border-radius: 10px;
}

a[href="https://www.u8gamers.com"]{
    color: rgb(72, 194, 255);
}

.aboutSection h2{
    font-size: 2.5rem;
    margin-bottom: .5rem;
}