.events-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    align-content: space-around;
    gap: 30px;
    border-radius: 5px;
    margin: 140px 20px;
  }
  
.events-page{
    padding-top: 200px;
    text-align: center;
}

  /* Media query for smaller screens */
  @media (max-width: 480px) {
    .events-page {
      padding: 10px;
    }
  }

.events__title{
  font-family: 'Press Start 2P', cursive;
  color:#6F2232;
}