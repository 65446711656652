.gamer {
    margin-top: 8rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    width: 100%;
    border-radius: 8px;
    border: 0px solid #e8e8e8;
    background-color: #fff;
    color: #BA5552;
    padding: 30px;
    transition: box-shadow 0.3s ease-in-out;
    max-width: 1080px;
  }
  .gamerTitle {
    font-weight: bold;
    text-align: center;
    font-family: 'Press Start 2P', cursive;
    color: #BA5552;
  }
  
  .gamer__image{
    width: 30%;
  }
  .gamerContent {
    margin-top: 10px;
    line-height: 1.75;
    display: flex;
    justify-content: space-around;
  }
  
  .gamer-author {
    font-weight: bold;
  }

  .gamer__desc{
    color: black;
    margin-top: 3rem;
    font-size: large;
  }

  .gamer__info{
    background-color: #BA5552;
    color: white;
    padding: 10px;
    border-radius: 5px;
    font-size: large;
    font-weight: bold;
    margin-top: 1rem;
  }